export function onlyAlphabet (str) {
  let regexp = /^[A-Za-z0-9-`’' _]*$/
  if (regexp.test(str)) {
    return true
  } else {
    return false
  }
}

export function validName (str) {
  let regexp = /^[A-Za-z0-9-`’' _]*$/
  if (regexp.test(str)) {
    return true
  } else {
    return false
  }
}

export function checkSsn (str) {
  let regexp = /^[0-9]{9}$/
  if (regexp.test(str)) {
    return true
  } else {
    return false
  }
}

export function checkSecurityCode (str) {
  let regexp = /^([0-9]{3}|[0-9]{4})$/
  if (regexp.test(str)) {
    return true
  } else {
    return false
  }
}

export function checkValidPhoneNumber (str) {
  let regexp = /^[0-9]{10}$/
  if (regexp.test(str)) {
    return true
  } else {
    return false
  }
}

export function checkValidZip (str) {
  let regexp = /^[0-9]{5}$/
  if (regexp.test(str)) {
    return true
  } else {
    return false
  }
}

export function checkEmail (emailAddress) {
  let regexEmail =  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (emailAddress.match(regexEmail)) {
    return true;
  } else {
    return false;
  }
}

export function accountNumber (str) {
  let regexp = /^[0-9]{4,17}$/
  if (regexp.test(str)) {
    return true
  } else {
    return false
  }
}

export function checkCreditCardNumber (str) {
  let regexp = /^[a-zA-Z0-9]{13,25}$/
  if (regexp.test(str)) {
    return true
  } else {
    return false
  }
}

// Check if the input date is within the next two months
export function checkValidDate(str) {
  let effictiveDate= localStorage.getItem('effectiveDate');
  let regexp = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/((?:19|20)\d\d)$/;

  if (!regexp.test(str)) {
    return false;
  }

  let parts = str.split('/');
  let month = parseInt(parts[0], 10);
  let day = parseInt(parts[1], 10);
  let year = parseInt(parts[2], 10);

  let currentDate = new Date(effictiveDate);

  let twoMonthsFromNow = new Date(currentDate);
  twoMonthsFromNow.setMonth(twoMonthsFromNow.getMonth() + 2);


  if (year > twoMonthsFromNow.getFullYear() || 
      (year === twoMonthsFromNow.getFullYear() && month > twoMonthsFromNow.getMonth() + 1) ||
      (year === twoMonthsFromNow.getFullYear() && month === twoMonthsFromNow.getMonth() + 1 && day > twoMonthsFromNow.getDate())) {
    return false;
  } else {
    return true;
  }
}

export function checkBenefitPercentage (str) {
  let regexp = /^[1-9][0-9]?$|^100$/
  if (regexp.test(str)) {
    return true
  } else {
    return false
  }
}

export function checkZero (number) {
  if (number <=0) {
    return false
  } else {
    return true
  }
}

export function checkInch (number) {
  if (number >= 0 && number <= 11) {
    return true
  } else {
    return false
  }
}


export function checkEmployerInformation (str) {
  let regexp = /^[a-zA-Z0-9\s!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/
  if (regexp.test(str)) {
    return true
  } else {
    return false
  }
}

export function isOver18 (dateOfBirth) {
  let today = new Date();
  let birthDate = new Date(dateOfBirth);
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  console.log('age', age)
  if (age >= 18) {
    return true;
  } else {
    return false;
  }
}

export function alphabetDigitSpace  (str) {
  let regexp = /^[A-Za-z0-9-`’' _]*$/
  if (regexp.test(str)) {
    return true
  } else {
    return false
  }
}

export function checkValidOTP (str) {
  let regexp = /^[0-9]{6}$/
  if (regexp.test(str)) {
    return true
  } else {
    return false
  }
}