<template>
  <div>
    <b-modal v-bind:hide-footer="true" id="OTP-Verification" centered size="md" @show="restOTP">
      <template #modal-header>
        <div class="popUp-header">
          <h4>Enter OTP </h4>
          <b-button variant="close-btn" @click="onClose">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div  class="text-center">
          <label>Please check your email or phone for OTP to view current member list.</label>
        </div>
        <div class="mt-2">
          <!-- <input type="number" class="custom-input"
                 placeholder="Enter OTP"
                 value=""
                 @input="$v.otp.$touch(); removeError();"
                 v-model="otp"
                 :class="{'form-control is-invalid': $v.otp.$error || otpErrorFlag}"
          /> -->

          <div class="opt-num-wrap">
            <div>
              <v-otp-input
                  @on-complete="onChangeOTP"
                  @on-change="handleOnChange"
                  ref="OtpInput"
                  :input-classes="errorClass"
                  separator="- "
                  :num-inputs="6"
                  :should-auto-focus="true"
                  :is-input-num="true"
              />
            </div>
          </div>

          <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.otp.required && $v.otp.$error">
            Please enter  OTP sent to your  registered email or phone number to proceed.
          </div>
          <div class="form-group__message  ml-3 mt-2" v-if="$v.otp.required && $v.otp.$error">
            Please enter valid OTP code.
          </div>
          <div class="error_message" v-if="otpErrorFlag">{{otpErrorMsg}}</div>
        </div>
        <div class="action-btn text-center mt-4">
          <!-- <b-button style="width:40%;"  class="button-brand mx-1" @click="checkVerifyCode">Verify Code</b-button> -->
          <b-button
              class="verify-btn mt-3"
              variant="success"
              @click="checkVerifyCode"
              :disabled="isProcessing"
          >
            {{ isProcessing ? "Verifying..." : "Verify" }}
          </b-button>

          <login-otp  @sendOTP="sendOtpCode"
                      verificationType="email"
                      phoneNumber="94111111111"
                      email="your email and phone."
                      :expirySecond=300 />

        </div>
        <div class="text-center mt-3 mb-2">
          <!-- <OTPTimer title="Didn't get Code? Resend Code"
                    @updateRemainingSecond="updateRemainingSecond"
                    @sendOTP=sendOtpCode :expirySecond=60 /> -->
        </div>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
// import OTPTimer from "./OTPTimer";
import axios from "axios";
import {required} from "vuelidate/lib/validators";
import {checkValidOTP} from "../../utils/validators";
import OtpInput from "@bachdgvn/vue-otp-input";
import NewLoginOTP from "../../components/NewLoginOTP";
export default {
  name: "OTPVerificationAgentGroup",
  components: {
    // OTPTimer,
    'v-otp-input': OtpInput,
    'login-otp': NewLoginOTP
  },
  mounted() {
    let app = this;
    app.agentId = window.localStorage.getItem('agent_id');
    app.groupId = window.localStorage.getItem('group_id');
    app.eprocess = window.localStorage.getItem('eprocess');
    app.agentCode = window.localStorage.getItem('closeAgentCode');
    app.groupCode = window.localStorage.getItem('closeGroupCode');
  },
  data () {
    return {
      otp: null,
      remaningSecond: null,
      otpErrorFlag: false,
      agentId: '',
      groupId: '',
      otpErrorMsg: '',
      eprocess: '',
      agentCode: '',
      groupCode: '',
      isProcessing: false
    }
  },
  validations: {
    otp: {
      required,
      checkValidOTP
    }
  },
  methods: {
    onChangeOTP(otp) {
      let app = this
      app.otp = otp
      app.checkVerifyCode();
    },
    handleOnChange (otp) {
      let app = this
      if (otp.length < 6) {
        app.otp = otp
      }
      if (app.otpErrorFlag) {
        app.otpErrorFlag = false
        app.errorClass = ''
        app.errorMsg = ''
      }
    },
    onClose () {
      let app = this
      app.$bvModal.hide('OTP-Verification')
    },
    sendOtpCode () {
      console.log('hello')
      let app = this
      app.$emit('sendOTPVerification')
    },
    restOTP() {
      let app = this
      app.otp= null
      app.otpErrorFlag = false
      app.$v.$reset();
    },
    removeError () {
      let app = this
      app.otpErrorFlag = false
      app.otpErrorMsg = ''
    },
    checkVerifyCode () {
      let app = this;
      app.$v.$touch();
      if (app.$v.$invalid) {
        return false
      } else {
        app.isProcessing = true
        let params = {
          'otp': app.otp
        };
        if (app.eprocess == 'rep') {
          params['agent_id'] = app.agentId,
          params['user_type'] = 'A'
        } else if (app.eprocess == 'group') {
          params['group_id'] = app.groupId,
          params['user_type'] = 'G'
        }
        axios.post(process.env.VUE_APP_API_BASE + '/verify-enrollment-otp', params)
            .then(function (response) {
              console.log(response)
              app.$bvModal.hide('OTP-Verification')
              if (app.eprocess == 'rep') {
                app.$store.dispatch('repCode', app.agentCode)
                app.$router.push('/agent-select-member')
              } else if (app.eprocess == 'group') {
                app.$router.push('/group-select-member')
                app.$store.dispatch('groupCode', app.groupCode)
              }
              app.isProcessing = false
            })
            .catch(function (error) {
              app.otpErrorFlag = true
              app.otpErrorMsg = error.response.data.message
              app.isProcessing = false
            });
      }
    },
    updateRemainingSecond (value) {
      let app = this
      app.remaningSecond = value;
      app.$emit('updateRemainingSecond', value);
    }
  }
}
</script>

<style>
#OTP-Verification #contact-input .form-control.is-valid {
  background-color: transparent !important;
}

#OTP-Verification .otp-box {
  border-radius: 4px;
  border: 1px solid #dddddd;
  max-width: 56px;
  max-height: 45px;
  padding: 1rem;
  margin-right: 0.8rem;
  outline: none;
}
#OTP-Verification .verify-btn {
  min-height: 45px;
  padding: 0.5rem 0.8rem;
  font-size: 14px;
}
#OTP-Verification .resend-timer-wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  font-size: 14px;
}
#OTP-Verification .resend-timer {
  color: #5e5e5e !important;
  margin-left: 2px;
  font-weight: 600;
}
#OTP-Verification .no-border {
  border: none;
  outline: none;
  width: 80%;
}
#OTP-Verification .update-btn {
  background-color: #0871bb;
  color: #fff;
  border-radius: 6px;
  font-size: 14px;
  padding: 0.2rem 0.8rem;
}

#OTP-Verification .verify-email {
  background-color: #11809c !important;
}
#OTP-Verification .send-btn {
  margin-bottom: 1rem;
}

#OTP-Verification .send-btn,
.verify-btn {
  width: 100%;
}
#OTP-Verification .dim-light {
  color: #ccc;
}
#OTP-Verification .resend-code-enable {
  color: #11809c;
  font-weight: 700;
  cursor: pointer;
}
#OTP-Verification .custom-check-wrap{
  background-color:#11809c ;
  border-radius: 50px;
  max-width: fit-content;
  max-height: fit-content;
  padding: 0.2rem 0.6rem;
  margin-left: 0.1rem 0.5rem;
}
#OTP-Verification .custom-check-wrap svg{
  filter: brightness(0) invert(1);
  border-radius: 0.8rem;
  font-size: 10px;
}

#OTP-Verification .opt-num-wrap {
  display: flex;
  align-items: center;
}

#OTP-Verification .opt-num-wrap input {
  width: 100%;
  height: 50px;
  padding: 5px;
  margin: 0 10px 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  outline: none;
}


#OTP-Verification .highlight_otp {
  border: 2px solid #e73737 !important;
}

#OTP-Verification .valid-error {
  color: #e73737;
  padding: 0 10px;
}
</style>