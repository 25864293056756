<template>
  <div>
    <div class="resend-timer-wrap">
      <div>
        <div v-if="remaningSecond">
          <div class="mb-2">
            OTP sent to <b>{{verificationType === 'email' ? email : phoneNumber}}</b>
          </div>
          <div>
           <span class="">
              <i class="fa fa-clock me-1 clock-icon"></i>
               Please wait <span class="resend-timer"> {{remaningSecond}} </span> seconds before requesting a new code.
            </span>
          </div>
        </div>
      </div>
      <div
      v-if="!remaningSecond"
          :class="remaningSecond ? 'disable-otp resend-container' : 'resend-otp-code mt-3'" @click="sendOtpCode">
        Resend Code
      </div>
    </div>
    <div v-if="remaningSecond">
      <div class="progress" style="margin-top:20px">
        <div
            class="progress-bar bg-success"
            role="progressbar"
            :style="{ width: remainingPercentage + '%' }"
            aria-valuenow="60"
            aria-valuemin="0"
            aria-valuemax="100"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Cookies from "js-cookie";
export default {
  name: "NewLoginOTP",
  props: {
    expirySecond: {
      type: Number,
      required: true,
      default: "0",
    },
    hideTimerContent: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    setSpinnerLoader: {
      type: Boolean,
      default: false
    },
    verificationType: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    phoneNumber: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      remaningSecond: 0,
      remainingPercentage: 100
    };
  },
  mounted() {
    //if (!Cookies.get("otp_wait_time_end")) {
    this.setTimer();
    //}
  },
  destroyed() {
    Cookies.remove("otp_logged_time");
  },
  methods: {
    setTimer() {
      if (!this.getCookieDate()) {
        this.setCookie();
      }
      this.setOTPInterval();
    },
    setCookie() {
      Cookies.set(
          "otp_logged_time",
          this.getTimeStamp(this.expirySecond).getTime(),
          {
            expires: this.getTimeStamp(this.expirySecond),
            secure: false,
          }
      );
    },
    getCookieDate() {
      return Cookies.get("otp_logged_time");
    },
    setOTPInterval() {
      let interval = setInterval(() => {
        let remaining = Math.round(
            (this.getCookieDate() - new Date().getTime()) / 1000
        );
        this.remaningSecond = isNaN(remaining) ? 0 : remaining;
        this.remainingPercentage =(this.remaningSecond / this.expirySecond) * 100;
        this.setSpinnerLoader = false
        if (!this.remaningSecond) {
          Cookies.set("otp_wait_time_end", 1,{expires: this.getTimeStamp(600),secure: false});
          clearInterval(interval);
        }
      }, 1000);
    },
    sendOtpCode() {
      if(this.remaningSecond) return;
      this.$emit("sendOTP");
      Cookies.remove("otp_wait_time_end");
      this.setTimer();
    },
    getTimeStamp(expirySecond) {
      return new Date(new Date().getTime() + expirySecond * 1000);
    },
    getMessage(remainingSecond) {
      return  remainingSecond ? '' : this.title
    },
    getOTPMessage(remainingSecond) {
      return  remainingSecond ? `${remainingSecond}s` : `Resend OTP`
    },
  },
};
</script>
<style scoped>
.resend-timer-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.resend-timer-wrap .resend-otp-code {
  text-align: right;
  cursor: pointer;
  color: #11809c;
}
.resend-timer-wrap .disable-otp {
  cursor: default;
  text-decoration: none;
}
@media (max-width:991px) {
  .resend-timer-wrap {
    display: block;
  }
}

.resend-container {
  position: relative;
  top: -15px;
}
</style>