<template>
  <div class="page-wrap receiptPage">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xxl-6">
          <div class="Thanksimg mb-4  mt-4" v-if="siteRedirection !== 'usag'">
            <img :src="thankYouImage" alt="Corenroll">
          </div>
          <h2>
            <span class="head_grey">Dear {{name}},<br>Thank you,</span>
          </h2>
          <p>Your confirmation number is #{{policyID}}. A receipt of your enrollment will be sent to you and also to the client with the subject "Enrollment Receipt"</p>
          <div class="rollback-btn">
            <button class="backHome" type="button" name="button" @click="checkHomeRedirection" v-if="siteRedirection !== 'usag'">Return to homepage</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios';

export default {
    name: "Confirmation",
    data: function () {
      return {
        policyID: '',
        name: '',
        tempId: '',
        eprocess: '',
        thankYouImage: require(process.env.VUE_APP_THANK_YOU),
        effectiveDate: '',
        siteRedirection: null,
        routeRedirection: ''
      }
    },
    mounted() {
      let app = this;
      app.tempId = window.localStorage.getItem('tempId');
    app.eprocess = window.localStorage.getItem("eprocess");
    app.open_type = window.localStorage.getItem("open_type");
    app.referral = window.localStorage.getItem("referral");
      app.fromSite = window.localStorage.getItem('fromSite');
      app.effectiveDate = window.localStorage.getItem("effectiveDate");
      app.siteRedirection = window.localStorage.getItem('siteRedirection');
      app.routeRedirection = window.localStorage.getItem('routeRedirection');
      app.checkEprocess();
      app.fetchEff();
      app.usagEnrollmentConformation();
      app.$bus.$emit("resetCartInfo");
    },
    methods: {
        fetchEff: function () {
            let app = this;
            axios.get(process.env.VUE_APP_API_BASE+'/get-receipt', {
            params: {
                enrollment_id: app.tempId,
            }
            })
            .then(function (response) {
                console.log(response);
                if (response.data.status == 'success') {
                  app.policyID = response.data.data.policy_id;
                  app.name = response.data.data.name;
                  response.data.callInvoice && app.generateInvoiceForEnrollment();
                  window.localStorage.removeItem('tempId');
                  window.localStorage.removeItem('dep_sync_temp_id');
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        },
        generateInvoiceForEnrollment() {
          let app = this;
          axios.post(
              process.env.VUE_APP_API_BASE_INVOICE + '/autoInvoiceGeneration',
              {
                effectiveDate: app.effectiveDate,
                policy_id: app.policyID
              },
              {
                headers: {
                  'Authorization': `${process.env.VUE_APP_AUTHORIZATION_INVOICE}`
                }
              }).then(function (response) {
            console.log(response.data.data.result);
            if (response.data.status === 'success') {
              console.log('Invoice generated');
            }
          })
              .catch(function (error) {
                console.log(error);
              });
        },
        checkEprocess: function() {
          let app = this;
          axios.get(process.env.VUE_APP_API_BASE+'/get-enrollment-temp-info', {
            params: {
              enrollment_id: app.tempId,
              column: 'eprocess',
            }
          })
          .then(function (response) {
            console.log(response.data.data.result);
            if (response.data.status == 'success') {
              app.eprocess = response.data.data.result;
            }

          })
          .catch(function (error) {
            console.log(error);
          });
        },
        checkHomeRedirection () {
          let app = this
          if (app.routeRedirection == 'pqs-group-direct-continue') {
            location.href = "/";
          } else {
            app.homePage()
          }
        },
        homePage: function () {
          if (this.open_type !== null && typeof this.open_type !== "undefined") {
            if (this.referral !== null && typeof this.referral !== "undefined") {
              this.$router.push("/referrals?rid=" + this.referral);
            } else {
              this.$router.push('/');
            }
          } else {
            if (this.eprocess === "rep") {
              this.$router.push("/agent-home");
            } else if (this.eprocess === "applicant") {
              this.$router.push("/member-home");
            } else if (this.eprocess === "group") {
              this.$router.push("/group-home");
            }
        }
      },
      usagEnrollmentConformation () {
        let app = this
        if (app.siteRedirection == 'usag') {
          top.postMessage("enrollment_complete", "*");
        }
      }
    }
}
</script>

<style scoped>

</style>
