<template>
  <div class="plan_info">

    <div>
      <div class="plan_questions mt-4 mb-4">
        <div class="top_head">
          <h1>{{planBlueBarText}}</h1>
        </div>
        <div class="message_box qualified" v-if="successFlag">
          <p v-html="successMessage">
          </p>
        </div>
        <div class="message_box qualified" v-if="errorFlag" style="background-color: #f2dede !important;">
          <p v-html="errorMessage">
          </p>
        </div>
      </div>
    </div>
    <div>
      <div v-if="!tempId">
          <search-edit @callFunctions="afterQuote" @backFunction=" back_function" :showDirect="true" :captureDependent.sync="captureDependent"/>
      </div>
    </div>

      <div v-if="tempId">
          <search-edit @callFunctionsupdate="afterQuote" :captureDependent.sync="captureDependent"/>
    </div>
      <div class="mt-5" v-if="isFilterDataSubmitted">
        <p>Selected Group is: <strong style="color:green;">{{ gname }}
          ({{ gcode }})</strong> <span v-if="usertype==='existing-user'"> | Member: <strong style="color:green;"
                                                                                            class="brand-color">{{
            username
          }}</strong></span>
          <span v-if="portal_name"> |  Selected Portal is: <strong style="color:green;">{{ portal_name }}
                    ({{ portal_code }})</strong> </span>
        </p>
      </div>
      <div v-if="!isHidden" :class="(hostName == envAssignHostName)?'row justify-content-center':'row justify-content-start'">
        <div id="incomeError" class="col-xxl-3 col-xl-3 col-lg-4 col-sm-6 col-12" v-if="enrollmentIdError == 1">

<!--          <a style="color: white;">
            <button @click="quoteFunction()" type="button" class="univ-btn"
                    :style="(hostName == envAssignHostName)?'padding: 14px 0px; height: 60px; font-size: 16px;':''">For a Custom Quote, Click Here</button>
          </a>-->
        </div>
      </div>
      <!-- <div v-if="!isHidden && hostName !== envAssignHostName" class="row justify-content-start">
        <div id="incomeError" class="col-xxl-3 col-xl-3 col-lg-4 col-sm-6 col-12" v-if="enrollmentIdError == 1">

          <a style="color: white;">
            <button @click="quoteFunction()" type="button" class="univ-btn">For a Custom Quote, Click Here</button>
          </a>
        </div>
      </div> -->

    <div id="incomeError" class="mt-3" style="color: red;" v-if="hourError == 1">Please enter a sufficient working hours to add product to the cart by clicking <a @click="showPopIn" style="color: skyblue;">here</a>.</div>
    <div id="incomeError" class="mt-3" style="color: red;" v-if="salaryError == 1">Please enter your annual salary to view rates by clicking <a @click="showPopIn" style="color: skyblue;">here</a>.</div>
    <div id="incomeError" class="mt-3" style="color: red;" v-if="stateError == 1">Plan not available in your state. Please change the state by editing your basic info above.</div>
    <div id="incomeError" class="mt-3" style="color: red;" v-if="tierError == 1">Plan not available for this tier. Please change the tier by editing your basic info above.</div>
    <div id="incomeError" class="mt-3" style="color: red;" v-if="ageError == 1">Plan not available for selected age group. Please edit your basic info above.</div>
    <div id="incomeError" class="mt-3" style="color: red;" v-if="metError == 1">Plan not available in the area. Please choose another area by editing your basic info above.</div>
    <div id="smokerError" class="mt-3" style="color: red;" v-if="smokerError == 1">Tobacco usage required. Please choose tobacco usage by clicking <a @click="showPopInTob" style="color: skyblue;">here</a>.</div>
    <div id="incomeError" class="mt-3" style="color: red;" v-if="planAgeError == 1">{{planAgeErrorMsg}}</div>
    <div id="incomeError" class="mt-3" style="color: red;" v-if="portalError == 1">{{portalErrorMsg}}</div>
    <div id="incomeError" class="mt-3" style="color: red;" v-if="dependentError">{{dependentError}}</div>
    <div id="blackoutError" style="color: red;" v-if="blackoutErr.value == true">{{blackoutErr.message}}</div>


    <div v-if="startApp == true">
      <new-question-plan></new-question-plan>

    </div>
    <div v-if="!checkDisability && hourError !== 1 && salaryError !== 1" class="mt-4 row popListing-edit">
      <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-12 mb-3" v-if="annualIncome > 0">
        <p><strong>Annual Salary:</strong> {{formatPrice(annualIncome)}} <br> <span><a @click="showPopIn" class="editBtn">Edit</a></span></p>
      </div>
      <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-12 mb-3" v-if="workingHours > 0">
        <p><strong>Working Hours:</strong> ${{workingHours}} <br> <span><a @click="showPopIn" class="editBtn">Edit</a></span></p>
      </div>
<!--      <new-disability-plan ref="dis"></new-disability-plan>-->
    </div>

    <!-- show aflac question start -->
    <div class="row" v-if="showAflacQuestion">
      <div class="col-12">
        <div class="planDetail-question card-block">
          <div v-if="!isTrueConditionChecked">
            <span class="form-group__message aflag-error-message"> Please answer yes in order to continue</span>
          </div>
          <div class="mt-4">
            <h3>Please answer following questions to view rates.</h3>
          </div>
          <div v-for="(aflacItem, index) in aflacQuestionList" :key="aflacItem.qid + index">
            <ul>
              <li>
                <div>
                  <div class="quesText">
                    {{aflacItem.question}}
                  </div>
                  <div class="quesRadio">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input"
                             type="radio"
                             id="inlineRadio1"
                             v-model="aflacItem.true_condition"
                             :value="1" >
                      <label class="form-check-label" for="inlineRadio1">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input"
                             type="radio"
                             id="inlineRadio2"
                             v-model="aflacItem.true_condition"
                             :value="0">
                      <label class="form-check-label" for="inlineRadio2">No</label>
                    </div>
                  </div>
                </div>
                <div>
                  <span class="form-group__message" v-if="tempAflacQuestionList[index].trueCondition == 1">*Answer should be Yes to continue</span>
                  <span class="form-group__message" v-else>*Answer should be Yes/No to continue</span>
                </div>
              </li>
            </ul>
          </div>
          <div class="row justify-content-end">
            <div class="col-md-3">
              <button @click="sendAflacQuestionList">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- show aflac question end -->

    <div v-if="planAgeError != 1 && startApp == false && checkDisability != true">
      <div class="prod_block">
        <!-- <div class="caption">
          <a class="plan_logo" href="#">
          </a>
          <h2>{{planName}}</h2>
          <div class="p_content" v-html="planHeader"></div>
          <div v-if="annualIncome > 0">
            <p>Annual Income: ${{annualIncome}} <span><a @click="showPopIn">Edit</a></span></p>
          </div>
          <div v-if="workingHours > 0">
            <p>Working Hours: ${{workingHours}} <span><a @click="showPopIn">Edit</a></span></p>
          </div>
        </div> -->
        <div v-if="plans.length > 0 && showAddToCart && membershipRequired">
        <elevate-membership @showOptions="$bvModal.show('custom-modal')" :elevatePlan='elevatePlans[0]' @onSelect='addCartPrudential'/>
        </div>
        <div v-else>
        <div class="message_box qualified" v-if="associationText != null && plans.length > 0">
          <p v-html="associationText" style="font-size: 13px !important;">
          </p>
        </div>
        <div class="message_box qualified" v-if="checkPrudential == true && plans.length > 0">
            <p style="color:red; font-weight: bold; font-size: 13px !important;margin-top: 100px">
              Quotes, prices and/or rates displayed include 3% Administrative fees.
            </p>
          </div>
           <div class="message_box qualified" v-if="checkPrudential100k == true && plans.length > 0">
            <p style="color:red; font-weight: bold; font-size: 13px !important;margin-top: 100px">
              Quotes, prices and/or rates displayed include 3% Administrative fees.
              Optional Life plans are dependent on Medical Underwriting Approval.
            </p>
          </div>
        <div class="row">
          <div class="col-xxl-6">

          </div>
        </div>
        <div class="pd_listing m_prod mb-4" v-if="plans.length > 0 && showAddToCart">
          <div class="row">
            <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-12 mt-4"  v-for="(plan,index) in plans" v-bind:key="index">
                <div class="plan mainPlan-block card-block">
                  <div class="list_details">
                    <h3>{{plan.plan_name}}</h3>
                  </div>
                  <div class="price_container">
                    <div class="price coverage">

                      <span class="amount">${{plan.plan_price}}</span>
                    </div>

                  </div>
                  <div class="cart_button" v-if="plan.plan_type == 'MM' && platformCode == 'BROEXH'">
                    <button
                        type="button"
                        name="button"
                        id="callEnroll"
                        @click="$bvModal.show('call-enroll-modal')"
                    >
                      Call Today To Enroll
                    </button>
                  </div>
                  <div class="cart_button" v-else-if="checkPrudential">
                    <button type="button" name="button" id="addCart"
                           v-on:click="addCartPrudentialFinal(plan.pid,plan.ppid)"
                            v-if="(alreadyAnswered && ((plan.is_prudential_plan == true && plan.is_prudential == true) || (plan.is_prudential_above100k_plan == true)))" >Add to Cart</button>
                    <button type="button" name="button" id="enrollNow" v-on:click="startApp=true" v-else>Enroll Now</button>
                  </div>
                  <div class="cart_button" v-else>
                    <button type="button" name="button" id="addCart"
                            v-b-modal="'custom-modal'" v-on:click="addCartPrudential(plan.pid,plan.ppid)"
                            v-if="(alreadyAnswered && ((plan.is_prudential_plan == true && plan.is_prudential == true) || (plan.is_prudential_above100k_plan == true)) && popUpOff !=1)" >Add to Cart</button>
                    <button type="button" name="button" id="addCart" v-on:click="addCart(plan.pid,plan.ppid)"
                            v-else-if="(alreadyAnswered && ((plan.is_prudential_plan == false || plan.is_prudential == false) || (plan.is_prudential_plan == false || plan.is_prudential_above100k_plan == false))&& popUpOff == 1)">Add to Cart</button>
                    <button type="button" name="button" id="enrollNow" v-on:click="startApp=true" v-else>Enroll Now</button>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pd_listing suggested mt-4" v-if="suggestedPlans.length > 0">
        <h2>Suggested Products</h2>
        <div class="row">
          <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-12 mt-4" v-for="(plan,index) in suggestedPlans" v-bind:key="index">
              <div class="plan mainPlan-block card-block">
                <div class="pr_logo" style="padding-bottom: 1em;">
                  <img :src=plan.plan_logo alt="Dental"/>
                </div>
                <div class="list_details">
                  <h3>{{plan.plan_name}}</h3>
                </div>
                <div class="price_container">
                  <div class="price coverage">
                    <span class="amount">${{plan.price}}</span>
                  </div>
                </div>
                <div class="cart_button">
                  <button type="button" name="button" id="addCart" v-on:click="addCart(plan.plan_id,plan.plan_pricing_id)">Add to Cart</button>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="message_box qualified" v-if="checkPrudential100k == true && plans.length > 0">
            <p style="color:red; font-weight: bold; font-size: 13px !important;margin-left: 400px">
              Optional Life plans 100k - 300k issued by The Prudential Insurance Company of America<br>
              <span style="margin-left:90px">Require Evidence of Insurability and are NOT Guaranteed.</span><br>
              You will receive a decision from Prudential within 5 - 10 days of your enrollment.
            </p>
          </div>
          </div>
    </div>
    <!-- <plan-description></plan-description> -->
    <div class="plan_desc mt-5">
      <h2 v-if="planName">Plan Description of {{planName}}</h2>
      <div class="card-block plandesc_content d-block table-responsive">
        <div @click="handleClick" v-html="planDesc"></div>
        <button v-if="exclusionstextname" style="margin-top:1rem;" @click.prevent="[showExample = false , showFaq = false , showExclusion = showExclusion? false : true]"  type="button" class="univ-btn">{{exclusionstextname}}</button>
        <div style="margin-top:1rem;" v-if="showExclusion">
        <div  v-html="exclusionstext"></div>
        </div>
        <button v-if="exampletextname" style="margin-top:1rem;" @click.prevent="[showExclusion = false, showFaq = false , showExample = showExample? false : true ]"  type="button" class="univ-btn">{{exampletextname}}</button>
        <div style="margin-top:1rem;" v-if="showExample">
        <div  v-html="exampletext"></div>
        </div>
        <button v-if="faqtextname" style="margin-top:1rem;" @click.prevent="[showExclusion = false , showExample = false , showFaq = showFaq? false : true]"  type="button" class="univ-btn">{{faqtextname}}</button>
        <div style="margin-top:1rem;" v-if="showFaq">
        <div  v-html="faqtext"></div>
      </div>
      </div>
    </div>







    <!-- Income Pop up -->
    <b-modal id="incomeError" centered size="md"  v-model="visibleIn">
        <template #modal-header>
            <div class="popUp-header">
                <h4>Annual Salary</h4>

                <b-button variant="close-btn" @click="visibleIn=false">
                    <i class="fas fa-times"></i>
                </b-button>
            </div>
        </template>
        <b-container fluid>
            <div class="planPop-details">
              <div class="content">
                <form class="" action="" method="post">
                  <div class="input_box mb-3">
                    <label for="">Annual Salary</label>
                    <input type="number" class="custom-input" v-model="annualIncome">
                  </div>
                  <div class="input_box mb-3"  v-if="hourError == 1">
                    <label for="">Working Hours</label>
                    <input type="number" class="custom-input" v-model="workingHours">
                  </div>
                  <div class="button_box mb-3">
                    <button class="submit" type="button" name="button" @click="submitFilter">Submit</button>
                  </div>
                </form>
              </div>
            </div>
        </b-container>
    </b-modal>


    <!-- Ask tier for existing user  -->

<b-modal id="askTier" centered size="md" no-close-on-backdrop>
        <template #modal-header>
            <div class="popUp-header">
                <h4>Please choose the tier</h4>
<!--                <b-button variant="close-btn" @click="$bvModal.hide('askTier')">
                    <i class="fas fa-times"></i>
                </b-button>-->
            </div>
        </template>
        <b-container fluid>
            <div class="planPop-details">
              <div class="content">
                <form action="" method="post">
                    <div class="form-group row">
                      <div class="col-xxl-6 mb-4">
                        <label for="">Choose Tier</label>
                        <select name="" class="custom-input" v-model="tier" id="tier" value="Choose Tier">
                          <option value="IO" selected>Member Only</option>
                          <option value="IS">Member + Spouse</option>
                          <option value="IC">Member + Child(ren)</option>
                          <option value="IF">Family</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-xxl-6 mb-4">
                        <button type="button" class="univ-btn" @click="UsersubmitAPI">Submit</button>
                      </div>
                    </div>
                  </form>
              </div>
            </div>
        </b-container>
    </b-modal>

    <!-- Tobacco Pop up -->
    <b-modal id="smokerError" centered size="md"  v-model="visibleInTob">
        <template #modal-header>
            <div class="popUp-header">
                <h4>Tobacco Usage</h4>

                <b-button variant="close-btn" @click="visibleInTob=false">
                    <i class="fas fa-times"></i>
                </b-button>
            </div>
        </template>
        <b-container fluid>
            <div class="planPop-details">
              <div class="content">
                <form class="" action="" method="post">
                  <div class="input_box mb-3">
                    <label for="">Do you smoke?</label>
                    <select class="input zip custom-input" v-model="is_smoker" value="Choose Tier">

                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                  <div class="button_box mb-3">
                    <button class="submit" type="button" name="button" @click="submitFilterTob">Submit</button>
                  </div>
                </form>
              </div>
            </div>
        </b-container>
    </b-modal>

    <!-- Alert Pop up -->
    <b-modal id="alreadyActiveMsg" centered size="md"  v-model="alreadyActive">
        <template #modal-header>
            <div class="popUp-header">
                <h4>{{alreadyActiveMessage}}</h4>

                <b-button variant="close-btn" @click="alreadyActive=false">
                    <i class="fas fa-times"></i>
                </b-button>
            </div>
        </template>
        <b-container fluid>
            <div class="planPop-details">
              <div class="content">
                  <div class="button_box mb-3">
                    <button class="submit" type="button" @click="alreadyActive=false;alreadyActiveMessage=''" name="button">OK</button>
                  </div>
              </div>
            </div>
        </b-container>
    </b-modal>

    <!-- Pop up -->
    <b-modal id="custom-modal" size="xl" centered>
    <div class="popup-content">
       <b-button class="close-btn" @click="$bvModal.hide('custom-modal')">
           x
        </b-button>
      <h2>Elevate Wellness Membership</h2>
      <div class="row">
        <div class="col-md-4" v-for="(elevatePlan,index) in elevatePlans" v-bind:key="index">
          <div class="plan-grid ">
            <h3>{{elevatePlan.plan_name}}</h3>
            <span class="plan-price">${{elevatePlan.plan_price}}</span>
            <button type="button" class="planActionBtn" id="addCart" v-on:click="addCartPrudential(elevatePlan.pid,elevatePlan.ppid)">Select MemberShip</button>
          </div>
        </div>
      </div>
    </div>

    <div @click="handleClick" v-html="elevateFeeContents"></div>
  </b-modal>
    <!-- for select portal -->
    <b-modal v-bind:hide-footer="true" id="select_portal" centered size="md" no-close-on-backdrop>
          <template #modal-header>
            <div class="popUp-header" style="border-bottom: 0px !important">
              <div >
              <h4>Please select the portal you want to enroll with</h4>
              </div>
<!--                <b-button variant="close-btn" @click="$bvModal.hide('select_portal')">
                    <i class="fas fa-times"></i>
                </b-button>-->
            </div>
          </template>
          <b-container fluid>
            <div>
                   <label for="">Choose Enrollment Portal</label>
                    <select name="" class="custom-input" v-model="platformId" id="platformId">
                      <option v-for="item in platforms" :value="item.platform_group_id" v-bind:key="item.platform_group_id">{{item.platform_name}}</option>
                    </select>
                    <p style="color:red;" v-if="platformError['error'] == true">{{platformError['message']}}</p>
                    <div v-if="groupData.length !== 0 ">
                      <label for="">Choose an option to enroll a new member:</label>
                    <select name="" class="custom-input" v-model="group_id" id="group_id">
                      <option v-for="item in groupData" :value="item.gid" v-bind:key="item.gid">{{item.gname}}</option>
                    </select>
                    <p style="color:red;" v-if="platformError['error'] == true">{{groupErr['message']}}</p>
                    </div>

            </div>
            <div class="popUp-details text-center">
                <div v-if="saveSuccess" class="action-btn text-center mt-4">
                    <b-button style="width:100%;"  class="button-brand mx-1" @click="$bvModal.hide('select_portal')" data-dismiss="modal">Done</b-button>
                </div>
                <div v-else class="action-btn text-center mt-4">
                    <b-button  style="width:40%;"  class="dark-btn mx-1" @click="$bvModal.hide('select_portal')">Back</b-button>
                    <b-button v-if="groupData.length === 0" style="width:40%;"  class="button-brand mx-1" @click="choosePortal" data-dismiss="modal">Select</b-button>
                    <b-button v-else style="width:40%;"  class="button-brand mx-1" @click="groupselect()" data-dismiss="modal">Done</b-button>
                </div>
            </div>
          </b-container>

        </b-modal>

        <!-- For group portal select select_portal_only -->
        <b-modal v-bind:hide-footer="true" id="select_portal_only" centered size="md" no-close-on-backdrop>
          <template #modal-header>
            <div class="popUp-header" style="border-bottom: 0px !important">
              <div >
              <h4>Please select the portal you want to enroll with</h4>
              </div>
<!--                <b-button variant="close-btn" @click="$bvModal.hide('select_portal_only')">
                    <i class="fas fa-times"></i>
                </b-button>-->
            </div>
          </template>
          <b-container fluid>
            <div>
                   <label for="">Choose Enrollment Portal</label>
                    <select name="" class="custom-input" v-model="platformId" id="platformId">
                      <option v-for="item in platforms" :value="item.platform_group_id" v-bind:key="item.platform_group_id">{{item.platform_name}}</option>
                    </select>
                    <p style="color:red;" v-if="platformError['error'] == true">{{platformError['message']}}</p>

            </div>
            <div class="popUp-details text-center">
                <div  class="action-btn text-center mt-4">
                    <b-button  style="width:40%;"  class="dark-btn mx-1" @click="$bvModal.hide('select_portal_only')">Back</b-button>
                    <b-button  style="width:40%;"  class="button-brand mx-1" @click="choosePortal" data-dismiss="modal">Select</b-button>
                </div>
            </div>
          </b-container>
        </b-modal>
    <call-to-enroll />
    <!-- <div  v-show="alreadyActive" class="error_msg popups">
      <div class="popup_container">
        <a @click="closePopInAlreadyActive" class="close" href="#">x</a>
        <div class="content grey_box">
          <h2>{{alreadyActiveMessage}}</h2>
          <div class="button_box">
            <button class="submit" type="button" @click="alreadyActive=false;alreadyActiveMessage=''" name="button">OK</button>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from 'axios';
import NewQuestionPlan from "./NewQuestionPlan";
// import NewDisabilityPlan from "./NewDisabilityPlan";
import ElevateMembership from "./ElevateMembership.vue"
import SearchEdit from '../SearchEdit.vue'
import CallToEnroll from "./CallToEnroll.vue"
export default {
  name: "PlanDetail",
  components: {
    'search-edit':SearchEdit,
    'new-question-plan': NewQuestionPlan,
    'elevate-membership':ElevateMembership,
    'call-to-enroll': CallToEnroll
    // 'new-disability-plan': NewDisabilityPlan
  },
  data: function () {
    return {
        tempId: '',
        plans: [],
        gcode: '',
        planName: '',
        planDesc: '',
        planHeader: '',
        salaryError: 0,
        enrollmentIdError: 0,
        ageError: 0,
        metError: 0,
        tierError: 0,
        stateError: 0,
        smokerError: 0,
        visibleIn: false,
        visibleInTob: false,
        annualIncome: '',
        category: '',
        dob: '',
        tier: 'IO',
        state: '',
        zip: '',
        effectiveDate: '',
        gender: '',
        smoker: '',
        is_smoker: '0',
        genderOpt: '',
        eprocess: '',
        agent_id: '',
        group_id: '',
        userId: '',
        enrollmentType: '',
        blackoutErr: {
          value: false,
          message: '',
        },
        alreadyInCart: [],
        set_smoker: false,
        planAgeError: 0,
        planAgeErrorMsg: '',
        portalError:0,
        portalErrorMsg: '',
        suggestedPlans: [],
        alreadyActive: false,
        alreadyActiveMessage: '',
        newQuestionsError: 0,
        planBlueBarText: '',
        successMessage: '',
        successFlag: false,
        errorFlag: false,
        errorMessage: '',
        plan_id: '',
        startApp: false,
        alreadyAnswered: false,
        associationText: null,
        checkDisability: false,
        workingHours: '',
        hourError: 0,
        elevateFeeContents: '',
        elevatePlans: [],
        addedPid: '',
        addedPpid: '',
        isHidden: false,
        showEdit:false,
        saveSuccess:false,
        groupData: [],
        groupErr: [],
        platforms: [],
        platformError: [],
        platformId: '',
        checkPrudential: false,
        exclusionstextname: '',
        exclusionstext: '',
        exampletextname: '',
        exampletext: '',
        faqtextname: '',
        faqtext: '',
        showExclusion: false,
        showExample:false,
        showFaq: false,
        checkPrudential100k: false,
        isFilterDataSubmitted: false,
        portal_name: '',
        portal_code: null,
        usertype: '',
        username: '',
        gname: '',
        planId: null,
        isPremierPlan: false,
        popUpOff:0,
        portalList: [],
        showAflacQuestion: false,
        isTrueConditionChecked: true,
        aflacQuestionList: [],
        tempAflacQuestionList: [],
        showAddToCart: false,
        portal: {},
        hostName: location.host,
        envAssignHostName: process.env.VUE_APP_SITE_HOST_NAME,
        membershipRequired:false,
        platformCode: null,
        captureDependent:false,
        dependentError:'',
        fromSite: null
    }
  },
  mounted() {
    let app = this;
    app.tempId = window.localStorage.getItem('tempId');
    app.agent_id = window.localStorage.getItem('AGENT_ID');
    app.group_id = window.localStorage.getItem('group_id');
    app.platformId = window.localStorage.getItem('portal_id');
    app.eprocess=window.localStorage.getItem('eprocess');
    app.open_type = window.localStorage.getItem('open_type');
    app.enrollType=window.localStorage.getItem('enroll_type');
    app.plan_id = app.$route.params.id;
    app.uid = window.localStorage.getItem('USER_ID');
    app.portal_name = window.localStorage.getItem('portal_name');
    app.portal_code = window.localStorage.getItem('portal_code');
    app.usertype = window.localStorage.getItem('usertype');
    app.username = window.localStorage.getItem('username');
    app.gname = window.localStorage.getItem('gname');
    app.gcode = window.localStorage.getItem('gcode');
    app.planId = window.localStorage.getItem('planId');
    app.fromSite = window.localStorage.getItem('fromSite');
    app.isFilterDataSubmitted = window.localStorage.getItem('isFilterDataSubmitted');
    app.platformCode = window.localStorage.getItem('portal_code');
    app.checkForPremierPlan();
    app.checkEprocess();
    app.getType();
    app.getFilters();
    app.getPlanDetails();
    app.getPlanPricing();
    app.getSuggestedPlans();
    app.checkDisabilityPlan();
    app.checkPrudentialPlan();
    app.checkPrudentialPlan100k();
    app.getAflacQuestion();
    app.getInfo();
    app.quoteFunction();
    // app.group_name = app.$store.state.group.gname;
  },
  methods: {
    afterQuote: function(){
          let app = this;
          app.tempId = window.localStorage.getItem('tempId');
          app.isFilterDataSubmitted = window.localStorage.getItem('isFilterDataSubmitted');
          app.getPlanDetails();
          app.getPlanPricing();
          app.getSuggestedPlans();
          app.checkDisabilityPlan();
          app.checkPrudentialPlan();
          app.getFilters();
          app.getInfo();
          app.getAflacQuestion();
    },
    formatPrice (annualIncome) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
      const formattedAmount = formatter.format(annualIncome);
      return formattedAmount
    },
    // Aflac question logic starts
    getAflacQuestion () {
      let app = this;
      app.showAddToCart = false;
      axios.get(process.env.VUE_APP_API_BASE + '/get-aflac-questions', {
        params: {
          plan_id: app.plan_id,
          enrollment_id: app.tempId,
        }
      })
        .then(function (response) {
          if (response.data.statusCode == 200) {
            app.showAflacQuestion = true
            app.aflacQuestionList = []
            app.aflacQuestionList = response.data.data
            response.data.data.forEach( ele => {
              let query = {};
              query['question'] = ele.question
              query['trueCondition'] = ele.true_condition
              app.tempAflacQuestionList.push(query)
            })
          } else if (response.status == 204) {
            app.showAddToCart = true;
            app.showAflacQuestion = false;
          } else {
            app.showAddToCart = true;
            app.showAflacQuestion = false;
          }
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    sendAflacQuestionList () {
      let app = this
      let BreakException;
      try {
        app.tempAflacQuestionList.forEach( (ele, index) => {
          if (ele.trueCondition == 1) {
            if (ele.trueCondition == app.aflacQuestionList[index].true_condition) {
              app.isTrueConditionChecked = true
            } else {
              app.isTrueConditionChecked = false
              throw BreakException;
            }
          }
        })
      } catch (e) {
        if (e !== 'BreakException') throw e
      }
      if (app.isTrueConditionChecked) {
        let payload = []
         app.aflacQuestionList.forEach( ele => {
          let query = {};
          query['qid'] = ele.qid
          query['true_condition'] = ele.true_condition.toString();
          payload.push(query)
        })
        axios.post(process.env.VUE_APP_API_BASE + '/save-aflac-questions', {
          enrollment_id: app.tempId,
          plan_id: app.plan_id,
          aflac_data: payload
        })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.showAddToCart = true;
              app.showAflacQuestion = false;
            }
          })
          .catch(function (error) {
            console.log(error)
          });
      }
    },
    // Aflac question logic ends
    getType: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE + '/get-enrollment-temp-info', {
        params: {
          enrollment_id: app.tempId,
          column: 'type',
        }
      })
          .then(function (response) {
            console.log(response.data.data.result);
            if (response.data.status == 'success') {
              app.enrollmentType = response.data.data.result;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    checkPrudentialPlan() {
      let app = this;
      let pid = app.$route.params.id;
      axios.get(process.env.VUE_APP_API_BASE+'/check-prudential-plan', {
        params: {
          enrollment_id: app.tempId,
          plan_id: pid,
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.checkPrudential = response.data.data.status;
              app.membershipRequired=app.checkPrudential;
              app.captureDependent=response.data.data.depdob;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    checkPrudentialPlan100k() {
      let app = this;
      let pid = app.$route.params.id;
      axios.get(process.env.VUE_APP_API_BASE+'/check-prudential-above-100k', {
        params: {
          enrollment_id: app.tempId,
          plan_id: pid,
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
                app.checkPrudential100k = response.data.data.status;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    checkEprocess: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE + '/get-enrollment-temp-info', {
        params: {
          enrollment_id: app.tempId,
          column: 'eprocess',
        }
      })
          .then(function (response) {
            console.log(response.data.data.result);
            if (response.data.status == 'success') {
              app.eprocess = response.data.data.result;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getSuggestedPlans: function () {
      // Submit API and
      // Navigate to plans page
      let app = this;
      let pid = app.$route.params.id;
      axios.get(process.env.VUE_APP_API_BASE + '/get-related-plans', {
        params: {
          enrollment_id: app.tempId,
          plan_id: pid
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.suggestedPlans=response.data.data
            }
            console.log(app.suggestedPlans);
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    quoteFunction: function(){
      let app = this;
      if (app.fromSite !== 'elevate-direct') {
        app.isHidden = false;
        if (!app.enrollType && !app.tempId && app.uid) {
          app.$bvModal.show('askTier');
        } else if (app.platformId == null || window.localStorage.getItem('group_id') == null) {
          app.getPlatforms();
          if (app.eprocess == 'rep') {
            app.$bvModal.show('select_portal');
          } else {
            app.$bvModal.show('select_portal_only')
          }
        } else {
          app.showEdit = true;
          app.isHidden = true;
        }
      }
    },
    getElevateFeeContents: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-plan-detail')
      .then(function (response) {
        if (response.data.status == 'success') {
          app.elevateFeeContents   = response.data.data.plan_desc.body_text;
          app.getElevatePricing();
        }
      })
      .catch(function (error) {
          console.log(error);
      });
    },
    checkCartPlan: function (pid, ppid) {
      let app = this;
      axios.post(process.env.VUE_APP_API_BASE + '/check-already-in-cart', {
        enrollment_id: app.tempId,
        plan_id: pid,
        plan_pricing_id: ppid,
      })
          .then(function (response) {
            console.log(response.data.data);
            if (response.data.status == 'success') {
              if (response.data.data.status == true) {
                app.alreadyInCart[ppid] = true;
              } else {
                app.alreadyInCart[ppid] = false;
              }
            }
            console.log(app.alreadyInCart);
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getGroups: function(platform) {
      let app = this;
      let params = {}
      params['agent_id'] = app.agent_id
      platform && (params['platform_group_id'] = platform)
      axios.get(process.env.VUE_APP_API_BASE+'/get-agent-groups', { params: params})
          .then(function (response) {
            console.log(response.data.data);
            if (response.data.status == 'success') {
              app.groupData = response.data.data;
              app.group_id=app.platformId;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    back_function(){
      let app = this;
      app.isHidden=false;
      app.showEdit=false;
    },
    groupselect: function() {
      this.platformPopup = false;
      this.visible = true;
      this.groupErr = [];
      if (this.group_id == '') {
        this.groupErr['error'] = true;
        this.groupErr['message'] = "Please choose a group";
      }else{
        window.localStorage.setItem('group_id', this.group_id);
        window.localStorage.setItem('enroll_type', 'new-group-member');
        this.$bvModal.hide('select_portal');
        this.isHidden=true;
        this.showEdit=true;
        this.$bus.$emit('renderGroupAndPortalInfo')
      }
    },
    getPlatforms: function() {
      let app = this;
      app.platforms = [];
      app.portalList = [];
      axios.get(process.env.VUE_APP_API_BASE+'/get-enrollment-platforms', {
        params: {
          agent_id: app.agent_id,
        }
      })
          .then(function (response) {
            console.log(response.data.data);
            if (response.data.status == 'success') {
              app.platforms = response.data.data;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    choosePortal: function() {
      let app = this;
      app.platformError = [];
      console.log('platforms' + app.platformId);
      if (app.platformId !== '' ) {
        window.localStorage.setItem('portal_id', this.platformId);
        if(app.eprocess == 'rep'){
            app.getGroups(app.platformId);
        }
        else{
          window.localStorage.setItem('enroll_type', 'new-group-member');
          app.$bvModal.hide('select_portal_only');
          app.isHidden=true;
          app.showEdit=true;
          }
      }else{
        app.platformError['error'] = true;
        app.platformError['message'] = "Please choose a platform";
      }
    },
    getFilters: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE + '/get-enrollment-temp-info', {
        params: {
          enrollment_id: app.tempId,
          column: 'filter_info'
        }
      })
          .then(function (response) {
            console.log(response.data.data.result);
            if (response.data.status == 'success') {
              app.dob = response.data.data.result.dob;
              app.zip = response.data.data.result.zip;
              app.state = response.data.data.result.state;
              app.tier = response.data.data.result.tier;
              app.gender = response.data.data.result.gender;
              if (app.gender == '0') {
                app.genderOpt = "M";
              } else {
                app.genderOpt = "F";
              }
              app.is_smoker = response.data.data.result.is_smoker;
              if (response.data.data.result.is_smoker == 1) {
                app.smoker = "Yes";
              } else {
                app.smoker = "No";
                app.is_smoker = 0;
              }
              if (response.data.data.result.is_smoker == null || response.data.data.result.is_smoker == '' || response.data.data.result.is_smoker == undefined) {
                app.set_smoker = false;
              } else {
                app.set_smoker = true;
              }
              // app.eprocess = response.data.data.result.eprocess;
              app.annualIncome = response.data.data.result.annual_incode;
              app.workingHours = response.data.data.result.working_hours;
              if (app.enrollmentType == 'existing-user') {
                app.userId = response.data.data.result.user_id;
              }
            }
            console.log(app.set_smoker + '-' + app.is_smoker);
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    submitFilter: function () {
      // Submit API and
      // Navigate to plans page
      let app = this;
      let params = {
        zip: app.zip,
        dob: app.dob,
        is_smoker: app.is_smoker,
        gender: app.gender,
        tier: app.tier,
        eprocess: app.eprocess,
        agent_id: app.agent_id,
        group_id: app.group_id,
        annual_incode: app.annualIncome,
        user_id: app.userId,
        working_hours: app.workingHours,
      };
      console.log(window.localStorage.getItem('eprocess'));
      //discard null values
      for (const key of Object.keys(params)) {
        if (params[key] === null || params[key] === '' || params[key] === undefined) {
          delete params[key];
        }
      }
      console.log(app.eprocess);
      window.localStorage.setItem('annualPrudentialSalary', app.annualIncome);
      axios.put(process.env.VUE_APP_API_BASE + '/change-filter-info/' + app.tempId, params)
          .then(function (response) {
            if (response.data.status == 'success') {
              console.log("success");
              app.closePopIn();
              app.getFilters();
              app.getPlanPricing();
              app.$refs.dis.loadPlans();
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    submitFilterTob: function () {
      // Submit API and
      // Navigate to plans page
      let app = this;
      let pid = app.$route.params.id;
      let params = {
        zip: app.zip,
        dob: app.dob,
        is_smoker: app.is_smoker,
        gender: app.gender,
        tier: app.tier,
        eprocess: app.eprocess,
        agent_id: app.agent_id,
        group_id: app.group_id,
        plan_id: pid,
        annual_incode: app.annualIncome,
        user_id: app.userId,
      };
      //discard null values
      for (const key of Object.keys(params)) {
        if (params[key] === null || params[key] === '' || params[key] === undefined) {
          delete params[key];
        }
      }
      console.log(app.eprocess);
      axios.put(process.env.VUE_APP_API_BASE + '/change-filter-info/' + app.tempId, params)
          .then(function (response) {
            if (response.data.status == 'success') {
              app.smokerError = 0;
              app.set_smoker = true;
              console.log("success");
              app.closePopInTob();
              app.reloadPage(response.data.data.plan_id);
            } else {
              // app.smokerError = 1;
              app.set_smoker = false;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    UsersubmitAPI: function () {
                // Submit API and
                // Navigate to plans page
                let app = this;
                axios.post(process.env.VUE_APP_API_BASE+'/add-filter-for-member', {
                    user_id: app.uid,
                    tier: app.tier,
                })
                .then(function (response) {
                    if (response.data.status == 'success') {
                        window.localStorage.setItem('tempId', response.data.data.temp_enrollment_id);
                      app.afterQuote();
                      app.$bvModal.hide('askTier');
                      app.isHidden=true;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
    submitAPI: function () {
      // Submit API and
      // Navigate to plans page
      let app = this;
      axios.post(process.env.VUE_APP_API_BASE + '/add-filter-for-new-user', {
        zip: app.zip,
        dob: app.dob,
        // is_smoker: app.is_smoker,
        gender: app.gender,
        tier: app.tier,
        eprocess: app.eprocess,
        agent_id: app.agent_id,
        group_id: app.group_id,
        platform_group_id: app.platformId,
        type: app.enrollType,
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              window.localStorage.setItem('tempId', response.data.data.temp_enrollment_id);
              window.localStorage.setItem('show-quotation', true);
              app.closeFilterData();
              app.relPage();
              app.formErrorFlag = [];
              app.formErrors = [];
            }
          })
          .catch(function (error) {
            app.formErrorFlag = [];
            app.formErrors = [];
            console.log(error);
            if (error.response.data.status == 'error') {
              error.response.data.data.forEach(function (item) {
                app.formErrorFlag[item.target_element] = true;
                app.formErrors[item.target_element + '_message'] = item.error_message;
              });
            }
          });
    },
    submitFilterAPI: function () {
      // Submit API and
      // Navigate to plans page
      console.log('here is the temp id')
      let app = this;
      axios.post(process.env.VUE_APP_API_BASE+'/add-filter-for-new-user', {
        zip: app.zip,
        dob: app.dob,
        // is_smoker: app.is_smoker,
        gender: app.gender,
        tier: app.tier,
        eprocess: app.eprocess,
        agent_id: app.agent_id,
        group_id: app.group_id,
        platform_group_id: app.platformId,
        type: app.enrollType,
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              window.localStorage.setItem('tempId', response.data.data.temp_enrollment_id);
              window.localStorage.setItem('show-quotation', true);
              app.formErrorFlag = [];
              app.formErrors = [];
              app.isHidden=false;
              app.relPage();
            }
          })
          .catch(function (error) {
            app.formErrorFlag = [];
            app.formErrors = [];
            console.log(error);
            if (error.response.data.status == 'error') {
              error.response.data.data.forEach(function(item) {
                app.formErrorFlag[item.target_element] = true;
                app.formErrors[item.target_element+'_message'] = item.error_message;
              });
            }
          });
    },
    validateZip: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/validate-zip-code', {
        params: {
          zip_code: app.zip,
        },
      })
          .then(function (response) {
            console.log(response);
            app.zip_error_message = '';
            app.zipFlag = false;
          })
          .catch(function (error) {
            console.log(error.response.data.data[0]);
            if (error.response.data.status == 'error') {
              // error.response.data.data.forEach(function(item) {
              app.zipFlag = true;
              app.zip_error_message = error.response.data.data[0].error_message;
              // });
            }
          });
      return app.zipFlag;
    },
    getPlanDetails: function () {
      // Submit API and
      // Navigate to plans page
      let app = this;
      let pid = app.$route.params.id;
      axios.get(process.env.VUE_APP_API_BASE + '/get-plan-detail/' + pid)
          .then(function (response) {
            if (response.data.status == 'success') {
              app.planDesc = app.removeSpecialSymbol(response.data.data.plan_desc.body_text);
              app.planName = response.data.data.plan_name;
              app.planHeader = response.data.data.plan_desc.header_text;
              app.planHeader = response.data.data.plan_desc.header_text;
              app.planBlueBarText = response.data.data.plan_desc.blueBarText;
              app.associationText = response.data.data.plan_desc.association_text;
              app.exclusionstextname = response.data.data.plan_desc.exclusions_text_name;
              app.exclusionstext = response.data.data.plan_desc.exclusions_text;
              app.exclusionstext = app.exclusionstext.replace(/â|€|¢|Ã‚|Â|Ã|‚|;/g, '').replace(/™/g, `'`).replace(/¬„/g, `'`).replace(/¬/g, `<span style="font-size:20px">&#8226;</span>`);
              app.exampletextname = response.data.data.plan_desc.example_text_name;
              app.exampletext = response.data.data.plan_desc.example_text;
              app.exampletext = app.exampletext.replace(/â|€|¢|Ã‚|Â|Ã|‚|;/g, '').replace(/™/g, `'`).replace(/¬„/g, `'`).replace(/¬/g, `<span style="font-size:20px">&#8226;</span>`);
              app.faqtextname = response.data.data.plan_desc.faq_text_name;
              app.faqtext = response.data.data.plan_desc.faq_text;
              app.faqtext = app.faqtext.replace(/â|€|¢|Ã‚|Â|Ã|‚|;/g, '').replace(/™/g, `'`).replace(/¬„/g, `'`).replace(/¬/g, `<span style="font-size:20px">&#8226;</span>`);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    removeSpecialSymbol (stringChar) {
      let stringArray= stringChar;
      let planDesc = "";
      for (let i=0; i<stringArray.length; i++) {
        if (stringArray.charCodeAt(i) <= 127) {
          planDesc += stringArray.charAt(i);
        } else {
          planDesc += stringArray.charAt(i).replace(/[^A-Z0-9]/ig, ' ')
        }
      }
      return planDesc
    },
    getPlanPricing: function () {
      let app = this;
      app.salaryError = 0;
      app.hourError = 0;
      let pid = app.$route.params.id;
      // let prudential = false;
      app.checkAlreadyAnswered();
      axios.get(process.env.VUE_APP_API_BASE + '/get-plan-pricing-detail', {
        params: {
          pid: pid,
          enrollment_id: app.tempId,
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.resetPlanPricingError();
              response.data.plans.forEach(function (item) {
                app.plans.push(item);
                // app.checkCartPlan(item.pid, item.ppid);
                if (item.is_prudential) {
                // prudential = true;
              }
              });
              app.newQuestionsError = 0;
              app.stateError = 0;
              // if (prudential) {
              app.getElevateFeeContents();
            // }
            } else {
              app.plans = [];
              if (response.data.status == 'error' && response.data.errorCode == 'changePid') {
                app.reloadPage(response.data.pid);
              }
              if (response.data.status == 'error' && response.data.errorCode == 'enrollmentIdError') {
                app.enrollmentIdError = 1;
              } else {
                app.enrollmentIdError = 0;
              }
              if (response.data.status == 'error' && response.data.errorCode == 'salaryError') {
                app.salaryError = 1;
              } else {
                app.salaryError = 0;
              }
              if (response.data.status == 'error' && response.data.errorCode == 'ageError') {
                app.ageError = 1;
              } else {
                app.ageError = 0;
              }
              if (response.data.status == 'error' && response.data.errorCode == 'tierError') {
                app.tierError = 1;
              } else {
                app.tierError = 0;
              }
              if (response.data.status == 'error' && response.data.errorCode == 'stateError') {
                app.stateError = 1;
              } else {
                app.stateError = 0;
              }
              if (response.data.status == 'error' && response.data.errorCode == 'metError') {
                app.metError = 1;
              } else {
                app.metError = 0;
              }
              if (response.data.status == 'error' && response.data.errorCode == 'smokerError') {
                app.smokerError = 1;
              } else {
                app.smokerError = 0;
              }
              if (response.data.status == 'error' && response.data.errorCode == 'planAgeError') {
                app.planAgeError = 1;
                app.planAgeErrorMsg = response.data.message;
              } else {
                app.planAgeError = 0;
                app.planAgeErrorMsg = ''
              }
              if (response.data.status == 'error' && response.data.errorCode == 'newQuestionsError') {
                app.newQuestionsError = 1;
              } else {
                app.newQuestionsError = 0;
              }
              if (response.data.status == 'error' && response.data.errorCode == 'hourError') {
                app.hourError = 1;
              } else {
                app.hourError = 0;
              }
               if (response.data.status == 'error' && response.data.errorCode == 'portalError') {
                app.portalError = 1;
                app.portalErrorMsg = response.data.message;
              } else {
                app.portalError = 0;
                app.portalErrorMsg = '';
              }
            }
          })
          .catch(function (error) {
            // console.log(error);
            app.dependentError=error.response.data.message;
            app.tierError = 0;
            app.hourError = 0;
            app.planAgeError = 0;
            app.stateError = 0;
            app.smokerError = 0;
            app.salaryError = 0;
          });
    },
    resetPlanPricingError () {
      let app = this
      app.dependentError='';
      app.plans = [];
      app.enrollmentIdError = 0;
      app.salaryError = 0;
      app.ageError = 0;
      app.tierError = 0;
      app.stateError = 0;
      app.metError = 0;
      app.smokerError = 0;
      app.newQuestionsError = 0;
      app.hourError = 0;
      app.planAgeError = 0;
      app.planAgeErrorMsg = ''
      app.portalError = 0;
      app.portalErrorMsg = '';
    },
     getElevatePricing: function() {
      let app = this;
      if(app.checkPrudential || app.checkPrudential100k){
      axios.get(process.env.VUE_APP_API_BASE+'/get-plan-pricing-detail-elevate', {
        params: {
          enrollment_id: app.tempId,
        }
      })
      .then(function (response) {
          if (response.data.status == 'success') {
            app.elevatePlans = [];
            response.data.plans.forEach(function(item) {
              app.elevatePlans.push(item);
            });
          } else {
            app.elevatePlans = [];
            app.popUpOff = 1;
          }
      })
      .catch(function (error) {
        console.log(error);
      });
      }
      else{
        app.popUpOff = 1;
      }
    },
    addCartPrudential: function (pid, ppid) {
      let app = this;
      app.addedPid = pid;
      app.addedPpid = ppid;
      this.membershipRequired=false;
      this.$bvModal.hide('custom-modal')
    },
    addCartPrudentialFinal: function (pid, ppid) {
      let app = this;
      let plans;
      if(localStorage.getItem('enroll_type')!='existing-member'){
        plans={
        enrollment_id: app.tempId,
        plan_id1: pid,
        plan_id: app.addedPid,
        plan_pricing_id1: ppid,
        plan_pricing_id: app.addedPpid,
        }
      }else{
        plans={
        enrollment_id: app.tempId,
        plan_id1: pid,
        plan_pricing_id1: ppid,
        }
      }
      axios.post(process.env.VUE_APP_API_BASE+'/add-plan-to-cart-prudentials',plans )
          .then(function (response) {
            if (response.data.status == 'success') {
              app.nextPage();
            }
          })
          .catch(function (error) {
            if (error.response.data[0].status == 'error') {
              // error.response.data.data.forEach(function(item) {
              if (error.response.data[0].errorCode == 'blackout') {
                app.blackoutErr.value = true;
                app.blackoutErr.message = error.response.data[0].message;
              } else if (error.response.data[0].errorCode == 'already-active'){
                console.log('correct');
                app.alreadyActive = true;
                app.alreadyActiveMessage = error.response.data[0].message;
              }
              else{
                app.blackoutErr.value = false;
                app.alreadyActive.value = false;
              }
              // });
            }
          });
    },
    addCart: function (pid, ppid) {
      let app = this;
      axios.post(process.env.VUE_APP_API_BASE + '/add-plan-to-cart', {
        enrollment_id: app.tempId,
        plan_id: pid,
        plan_pricing_id: ppid,
      })
          .then(function (response) {
            console.log(response);
            if (response.data.status == 'success') {
              app.nextPage();
            }
          })
          .catch(function (error) {
            console.log(error.response);
            if (error.response.data[0].status == 'error') {
              // error.response.data.data.forEach(function(item) {
              if (error.response.data[0].errorCode == 'blackout') {
                app.blackoutErr.value = true;
                app.blackoutErr.message = error.response.data[0].message;
              } else if (error.response.data[0].errorCode == 'already-active') {
                console.log('correct');
                app.alreadyActive = true;
                app.alreadyActiveMessage = error.response.data[0].message;
              } else {
                app.blackoutErr.value = false;
                app.alreadyActive.value = false;
              }
            }
          });
    },
    successMsg: function (msg) {
      this.successMessage = msg;
    },
    nextPage: function () {
      this.$router.push({name: 'cart'});
    },
    reloadPage: function (pid) {
      this.$router.push({name: 'plan', params: {id: pid}});
      this.getPlanDetails();
      this.getPlanPricing();
    },
    showFilterData() {
      this.visibleFilterData = true;
    },
    closeFilterData() {
      this.visibleFilterData = false;
    },
    showPopIn() {
      this.visibleIn = true;
    },
    closePopIn() {
      this.visibleIn = false;
    },
    showPopInTob() {
      this.visibleInTob = true;
    },
    closePopInTob() {
      this.visibleInTob = false;
    },
    closePopInAlreadyActive() {
      this.alreadyActive = false;
      this.alreadyActiveMessage = '';
    },
    checkAlreadyAnswered() {
      let app = this;
      let pid = app.$route.params.id;
      axios.get(process.env.VUE_APP_API_BASE + '/check-already-answered', {
        params: {
          enrollment_id: app.tempId,
          plan_id: pid,
        }
      })
          .then(function (response) {
            console.log(response.data.data);
            if (response.data.status == 'success') {
              if (response.data.data.status == true) {
                app.alreadyAnswered = false;
              } else {
                app.alreadyAnswered = true;
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    checkDisabilityPlan() {
      let app = this;
      let pid = app.$route.params.id;
      axios.get(process.env.VUE_APP_API_BASE + '/check-disability-plan', {
        params: {
          enrollment_id: app.tempId,
          plan_id: pid,
        }
      })
          .then(function (response) {
            console.log(response.data.data);
            if (response.data.status == 'success') {
              app.checkDisability = response.data.data.status;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    handleClick(e) {
      if (e.target.matches('.acc_caption')) {
        let captionBtn = e.target.matches('.acc_caption');
        console.log(captionBtn);
        // captionbtn.classList.toggle("active")
        // e.target.closest('.acc_text');
        // console.log(e.target.closest('.acc_text'));
        let target = e.target.parentNode.querySelector('.acc_text');
        target.classList.toggle("active")
        captionBtn.classList.toggle("active")
        // console.log(.classlist.add("active"));
        // #moreInfo.addClass(active);
        // var ddd = document.getElementsByClassName('acc_text');
        // console.log('ddde');
      }
    },
    relPage: function () {
      if (this.$route.name == 'plans') {
        this.$router.push('/plans');
      } else if (this.$route.name == 'plan') {
        let pid = this.$route.params.id;
        this.$router.push({name: 'plan', params: {id: pid}});
        window.location.reload()
      }
    },
    checkValue: function (str, max) {
      if (str.charAt(0) !== '0' || str == '00') {
        var num = parseInt(str);
        if (isNaN(num) || num <= 0 || num > max) num = 1;
        str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
      }
      return str;
    },
    eventDob: function () {
      let app = this;
      var input = app.dob;
      if (/\D\/$/.test(input)) input = input.substr(0, input.length - 1);
      var values = input.split('/').map(function (v) {
        return v.replace(/\D/g, '')
      });
      if (values[0]) values[0] = app.checkValue(values[0], 12);
      if (values[1]) values[1] = app.checkValue(values[1], 31);
      var output = values.map(function (v, i) {
        return v.length == 2 && i < 2 ? v + '/' : v;
      });
      app.dob = output.join('').substr(0, 10);
    },
    checkForPremierPlan () {
      let app = this
      axios.get(process.env.VUE_APP_API_BASE+'/get-more-requirement', {
        params: {
          plan_id: app.planId,
        }
      })
          .then(function (response) {
            app.isPremierPlan = response.data.data
            localStorage.setItem('isPremierPlan', app.isPremierPlan)
            app.$bus.$emit('checkPremierTier')
          })
          .catch(function (error) {
            console.log(error)
          });
    },
    getInfo: function() {
      let app = this;
      if (app.tempId != null) {
        axios.get(process.env.VUE_APP_API_BASE + '/get-group-agent-member-info?enrollment_id=' + app.tempId)
            .then(function (response) {
              if (response.data.status == 'success') {
                app.usertype = response.data.data.enrollment_type;
                if (response.data.data.enrollment_type == 'existing-user') {
                  app.username = response.data.data.result.user_info.first_name + ' ' + response.data.data.result.user_info.last_name;
                }
                app.gname = response.data.data.result.group_info.name;
                app.gcode = response.data.data.result.group_info.code;
                app.portal_code = response.data.data.result.platforms[0].platform_code
                app.portal_name = response.data.data.result.platforms[0].platform_name
                if (app.usertype) localStorage.setItem('usertype', app.usertype)
                if (app.username) localStorage.setItem('username', app.username)
                if (app.gname) localStorage.setItem('gname', app.gname)
                if (app.gcode) localStorage.setItem('gcode', app.gcode)
              }
            })
            .catch(function (error) {
              console.log(error);
            });
      }
    }
  },
  listener: {
    renderElevateInfoDirect () {
      let app = this
      app.platformId = window.localStorage.getItem('portal_id');
    }
  }
}
</script>

<style scoped>
</style>
